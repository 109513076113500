import { h } from "preact";
import { Router } from "preact-router";
import WlsCircle from "./wls-circle";
import { GraphQLClient, ClientContext } from "graphql-hooks";

// Code-splitting is automated for `routes` directory
import Confirmed from "../routes/confirmed";
import Reset from "../routes/reset";

const client = new GraphQLClient({
  url: `${process.env.API_URL}/graphql`,
});

const App = () => {
  return (
    <ClientContext.Provider value={client}>
      <div id="app">
        <div class="container">
          <WlsCircle />
          <Router>
            <Confirmed path="/" />
            <Reset path="/reset" />
          </Router>
        </div>
      </div>
    </ClientContext.Provider>
  );
};

export default App;
